$header-height: 70px !default;

.header {
  background-color: rgb(250, 251, 252);
  min-height: $header-height;
  margin-bottom: 5px;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

  .inner-header {
    .inner-header__logout-logo {
      width: 30px;
      height: 30px;
      margin-top: 20px;
      cursor: pointer;
    }

    .menu-field {
      display: flex;
      align-items: center;
      height: $header-height;

      .menu-field__logo {
        display: inline-block;
        width: 50px;
      }
    }
  }
}
