.menu-sidebar {
  display: inline-block;

  h2 {
    color: #ffffff;
  }

  .p-sidebar {
    background-color: #4baed0;
    border: 0;
    transition: 0.5s;
  }

  .p-sidebar-close:hover {
    color: #ffffff !important;
  }

  .p-link:focus {
    box-shadow: none;
  }

  .menu-sidebar__header {
    margin: 0;
    margin-top: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: #818181;
    font-size: 1em;
    font-weight: bold;
    cursor: default;
  }

  .menu-sidebar__bar-field {
    overflow-x: hidden;

    @media screen and (max-height: 450px) {
      padding-top: 15px;

      a {
        font-size: 18px;
      }
    }

    a {
      padding: 8px 8px 8px 32px;
      text-decoration: none;
      font-size: 1.3em;
      color: #343f4b;
      display: block;
      transition: 0.3s;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      &:hover {
        color: #f1f1f1;
      }
    }

    .closebtn {
      position: absolute;
      top: 0;
      right: 25px;
      font-size: 36px;
      margin-left: 50px;
      color: #818181;
      border: none;
      background: none;

      &:focus {
        outline: none;
      }
    }
  }

  .menu-sidebar__menu-button {
    display: block;
    // position: relative;
    top: 10px;
    left: 20px;
    width: 50px;
    height: 50px;
    font-size: 30px;
    cursor: pointer;
  }

  a.active {
    color: rgb(250, 250, 250);
    // text-decoration: underline;
  }
}

.menu_text {
  cursor: pointer;
}

.sidebar-panel{
  position: fixed;
  z-index: 1500;
  width: 30px;
  height: 100%;
  background-color: #4baed0;
  top: 0;
  left: 0;
  padding-top: 8px;
  @media screen and (max-height: 450px) {
    padding-top: 23px;
  }

  i{
    width: 100%;
    text-align: center;
    padding-top: 10px;
    height: 45px;

    &.active{
      color: rgb(250, 250, 250);
    }
  }
}
