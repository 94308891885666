.storage-point__image {
  margin: 7px 0 20px 0;
  width: 300px;
  height: 200px;
  display: block;
  background: url("/images/placeholder_200.png");
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.storage-point-form__service-row {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .storage-point-form__dropdown {
    flex: 5;
  }

  .storage-point-form__button {
    flex: 1;
  }
}