.notification-list {

  & > .p-dataview-content {
    background: none !important;
    border: none !important;
  }

  & > .p-dataview-header {
    background: none !important;
    border: none !important;
  }

  .notification-list__header {

  }

  .notification-list__item__clickable {
    cursor: pointer;
  }

  .notification-list__item__warning {
    background-color: rgba(255,60,0,0.2) !important;
  }

  .notification-list__item {
    margin: 10px 0;
    border: 1px solid #c8c8c8;
    border-radius: 8px;
    background-color: #ffffff;
    position: relative;

    .notification-list__item__content {
      display: inline-block;
      padding: 12px 50px 12px 12px;
    }

    .notification-list__item__action {
      position: absolute;
      top: 50%;
      right: 10px;
      margin-top: -1.2em;
    }

    .notification-list__item__new {
      font-weight: bold;
      color: rgba(33, 211, 92, 1);
    }

    .notification-list__item__label {
      font-size: 0.9em;
      //font-weight: bold;
      color: #7d7d7d;
      margin-left: 10px;
    }
  }
}