@import "styles/variables";

$body-background-color: rgb(240, 243, 245) !default;
$default-font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif !default;

body {
  font-family: $default-font-family;
  background-color: $body-background-color;
  min-width: 320px;
}

span {
  white-space: nowrap;
}

table {
  background-color: white;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

  td {
    &.button-cell {
      width: 100px;
    }
  }
}

.add_button_style {
  background: url("/images/plus2.png") center no-repeat;
  background-size: 30px;
  width: 50px;
  height: 50px;
  border-radius: 15px;
  border: none;

  &:focus {
    outline: none;
  }

  &:active {
    background: url("/images/plus.png") center no-repeat;
    background-size: 30px;
  }
}

.main_list_table {
  background-color: white;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

    thead tr th {
      background-color: #343a40 !important;
      color: #fffff3 !important;
    }
}

.custom-file-label:after {
  content: "Обзор" !important;
}