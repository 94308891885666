.order-service-list {
  background-color: white;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
  0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
  0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
  0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);

  thead > tr > th {
    height: 0;
    padding: 0 !important;
    background-color: #343a40 !important;
    color: #fffff3 !important;
  }

  .order-service-list__cell {
    display: flex;

    .order-service-list__cell__info {
      flex: 9;
      padding-left: 20px;
      padding-top: 20px;
      padding-bottom: 20px;

      .order-service-list__cell__info__title {
        font-size: larger;
        font-weight: bold;
      }

      .order-service-list__cell__info__prop {
        margin-bottom: 5px;
      }

      .order-service-list__cell__info__images {
        .imgBackPlate {
          position: fixed;
          width: 100vw;
          height: 100vh;
          background: rgba(0, 0, 0, 0.2);
          top: 0;
          left: 0;
          z-index: 5000;
        }

        img {
          width: 100px;
          margin: 2px;


          &.open {
            width: 60vh;
            height: 60vh;
            position: fixed;
            top: 50%;
            left: 50%;
            margin: -30vh 0 0 -30vh;
            box-shadow: 0 0 40px 5px rgba(0, 0, 0, 0.3);
            transform: none;
            z-index: 5005;

            @media (max-width: 1000px) {
              width: 80vw;
              height: 80vw;
              margin-top: -40vw;
              margin-left: -40vw;
            }
          }
        }

      }
    }

    .order-service-list__cell__price {
      font-weight: bold;
      font-size: larger;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      &::after {
        content: " р";
      }
    }
  }
}

.order-detail__change_status_button {
  color: #007ad9;
  cursor: pointer;
}

