.notification-block {
  position: relative;

  .notification-block__image {
    width: 30px;
    height: 30px;
    margin-top: 20px;
    cursor: pointer;
  }

  .notification-block__badge {
    position: absolute;
    background: red;
    color: white;
    min-width: 18px;
    font-size: 12px;
    border-radius: 20px;
    padding-left: 2px;
    padding-right: 2px;
    text-align: center;
    right: -4px;
    top: 10px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
  }
}