.order_list_table_style {
  background-color: white;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
  0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
  0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
  0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
}

.order_list_table_row_current_day {
  background-color: rgba(75, 176, 220, 0.2) !important;
}

.order_list_table_small_cell {
  display: flex;
  padding: 10px;
  color: black;

  .content {
    flex: 3;
    p {
      font-size: 13px;
    }
    span.data {
      font-size: 16px;
    }
  }
  .price {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 20px;
    font-weight: bolder;
  }
}

